<template>
    <div class="d-flex flex-nowrap w-100">
        <div class="p-0 icon-holder">
            <span class="arrow left" style="border-color: #FAFAFA; left: 47px;"></span>
            <div class="vertical-line"></div>
            <div class="icon-dot">
                <i v-if="item.type === 'Email'" class='fa fa-envelope-o'></i>
                <i v-else-if="item.type === 'External'" class='fa fa-external-link'></i>
                <i v-else-if="item.type === 'Assignment'" class='fa fa-user'></i>
                <i v-else-if="item.type === 'Transaction'" class='fa fa-dollar-sign'></i>
                <i v-else class='fa fa-pencil-square-o'></i>
            </div>
        </div>
        <div class="flex-grow-1 flex-shrink-1 w-100 pb-4 position-relative" style="right: -15px;">

            <div v-if="item.type === 'Task'" class="">
                <activity-item-task
                    :ref="`activity-${item.id}`"
                    :task="item.task"
                    :assigned-to-list="assignedToList"
                    :notify-to-list="notifyToList"
                    :activity="item"
                    :viewed="item.viewed"
                    @save="saveTask"
                    @close="closeTask"
                    @unread="markAsUnReadActivity"
                    @read="markAsReadActivity"
                    @delete="deleteTask"
                />
            </div>
            <div v-else-if="item.type === 'Email'" class="">
                <activity-item-email
                        :ref="`activity-${item.id}`"
                        :activity="item"
                        :viewed="item.viewed"
                        @unread="markAsUnReadActivity"
                        @read="markAsReadActivity"
                        :email="item.email"
                        @loadActivity="loadActivity"
                        @openMenuForEmailAttachments="openMenuForEmailAttachments"
                />
            </div>

            <div v-else class="transaction-info">
                <div>
                    <div class="row form-group" v-for="(content, i) of item.content" :key="i">
                        <div class="col-4 col-sm-3 align-top font-nunito--bold">{{ content[0] }}</div>
                        <div class="col-8 col-sm-9 align-top font-nunito--semibold">{{ content[1] }}</div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import ActivityItemTask from './activity-item-task';
    import ActivityItemEmail from './activity-item-email.vue';

    export default {
        name: "block-activity-item",
        props: {
            assignedToList: {
                type: Array,
                default() {
                    return [];
                }
            },
            notifyToList: {
                type: Array,
                default() {
                    return [];
                }
            },
            item: {
                type: Object,
                default() {
                    return {
                        id: '',
                        type: '',
                        task: {},
                        content: [],
                        createdOn: '',
                        viewed: false,
                    };
                }
            }
        },
        computed: {},
        methods: {
            saveTask: function ({task, activityId}) {
                this.$emit('saveTask', task);

                this.$nextTick(() => {
                    this.openActivityTaskDetails(activityId);
                });
            },
            openActivityTaskDetails(activityId) {
                if (!activityId) return;
                const ref = this.$refs[`activity-${activityId}`][0];
                ref.open();
                this.$nextTick(() => {
                    ref.$el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest'
                    });
                });
            },
            closeTask: function (data) {
                console.log('closeTask', data);
                this.$emit('closeTask');
            },
            deleteTask: function (task) {
                task.delete = true;
                this.$emit('deleteTask', task);
            },
            markAsReadActivity: function (item) {
                //this.$store.dispatch('updateActivityViewedStatus', {activity_id: item.activity_id, card_id: this.cardId, viewed: 1});
                this.$emit('markAsReadActivity', item);
            },
            markAsUnReadActivity: function (item) {
                //this.$store.dispatch('updateActivityViewedStatus', {activity_id: item.activity_id, card_id: this.cardId, viewed: 0});
              this.$emit('markAsUnReadActivity', item);
            },
            loadActivity: function () {
                this.$emit('loadActivity');
            },
            destroyMe: function () {
                Vue.$destroy(this);
            },
            filterActivity(activityText, query) {
                let text = '';
                if (!query) {
                    text = noteText;
                } else {
                    console.log('activityText', activityText);
                    if (activityText) {
                        text = activityText.replaceAll(new RegExp(query, 'iug'), `<span style="background-color: yellow">${query}</span>`);
                    }
                }

                return text;
            },
            openMenuForEmailAttachments: function (event, item) {
                this.$emit('openMenuForEmailAttachments', event, item);
            },
        },
        components: {
            ActivityItemTask,
            ActivityItemEmail,
        }
    };
</script>

<style scoped>

    .icon-holder {
        vertical-align: top;
        position: relative;
    }

    .icon-holder .vertical-line {
        border-right: lightgray 1px solid;
        position: absolute;
        top: 40px;
        bottom: 0;
        left: 0;
        width: 20px;
    }

    .icon-dot {
        color: white;
        font-size: 14px;
        border-radius: 99999px;
        width: 40px;
        height: 40px;
        background-color: var(--green);
        margin: 0;
        line-height: 40px;
        text-align: center;
        font-weight: bolder;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon-dot .bx {
        font-size: 24px;
    }

    .lineholder-l {
        width: 20px;
        border-right: 1px solid lightgray;
        padding: 0;
    }

    .lineholder-r {
        width: 20px;
        padding: 0;
    }

    .transaction-info {
        position: relative;
        border-radius: 5px;
        border: 1px solid lightgray;
        width: 100%;
        padding: 15px;
        background-color: #F7F7F7;
    }

    .transaction-info::after {
        /* content: ' '; */
        background: none;
        display: block;
        width: 20px;
        height: 10px;
        position: absolute;
        left: -20px;
        top: 4px;
        border: 5px solid rgba(0, 0, 0, 0);
        border-left: 10px solid rgba(0, 0, 0, 0);
        border-right: 10px solid lightgray;
    }

    .transaction-info table tr td {
        padding: 5px;
    }

    .transaction-info table tr td:first-child {
        width: 20%;
        min-width: 150px;
    }

</style>
