<script>
    /*eslint-disable */
    import {mapState} from 'vuex';
    import "@toast-ui/editor/dist/toastui-editor.css";
    import '@toast-ui/editor/dist/toastui-editor-viewer.css';
    import {Editor} from '@toast-ui/vue-editor';
    import {Viewer} from '@toast-ui/vue-editor';
    import Multiselect from 'vue-multiselect';
    import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
    import moment from 'moment';
    import datePicker from '@/views/utility/date-picker.vue';

    export default {
        name: 'activity-list-task',
        props: ['task', 'newTask', 'assignedToList', 'notifyToList', 'viewed', 'activity', 'openNewTaskForm'],
        data: function () {
            let dataTask = Object.assign({}, this.task, {notifyTo: Number(this.task.createdById)});
            if (dataTask && dataTask.dueTime === null) {
                dataTask.dueTime = "";
            }
            return {
                isOpenNewTaskForm: !!this.openNewTaskForm,
                dataTask: dataTask,
                isMinimized: true,
                dpOptions: {
                    autoclose: true,
                    todayHighlight: true,
                    format: 'dd/mm/yyyy'
                },
                // Notify Assignee Comment
                showCommentBox: false,
                isCommentAndNotifyClicked: false,
                comments: [],
                comment: '',
                priorityList: [{key: 'none', value: 'None'}, {key: 'low', value: 'Low'}, {key: 'medium', value: 'Medium'}, {key: 'high', value: 'High'}],
                typeList: [{key: 'to-do', value: 'ToDo'}, {key: 'call', value: 'Call'}, {key: 'email', value: 'Email'}],
                statusList: [{key: 'none', value: 'None'}, {key: 'new', value: 'New'}, {key: 'in-progress', value: 'In Progress'}, {key: 'completed', value: 'Completed'}],
            };

        },
        methods: {
            changeDueTime(e) {
                console.log('changeDueTime', e);
                if (e && e.displayTime) {
                    this.dataTask.dueTime = e.displayTime;
                }
            },
            saveTask: function () {
                console.log('saveTask', {
                    task: Object.assign({}, this.dataTask),
                    activityId: (this.activity && this.activity.activity_id > 0) ? this.activity.activity_id : 0,
                });
                // return;
                this.$emit('save', {
                    task: Object.assign({}, this.dataTask),
                    activityId: (this.activity && this.activity.activity_id > 0) ? this.activity.activity_id : 0,
                });
                this.dataTask.newComment = null;
            },
            saveTaskComment: function (comment) {
                this.$emit('save', {
                    task: {
                        id: this.task.id,
                        comments: [
                            comment,
                        ]
                    },
                });

            },
            closeTask: function () {
                this.dataTask = Object.assign({}, this.task);
                this.$emit('close');
            },
            clickMinimize: function () {
                this.isMinimized = !this.isMinimized;
            },
            markAsUnRead: function (a) {
                this.$emit('unread', a);
                this.dataTask.isViewed = false;
                this.saveTask();
            },
            markAsRead: function (a) {
                this.$emit('read', a);
                this.dataTask.isViewed = true;
                this.saveTask();
            },
            deleteTask: function (a) {
                if (!confirm('You are about to delete this Task.\nDo you want to continue?')) {
                    return;
                }
                if (!a) {
                    return this.closeTask();
                }
                this.$emit('delete', a.task);
            },
            destroyMe: function () {
                Vue.$destroy(this);
            },
            openDetails() {
                if (this.isMinimized) {
                    this.isMinimized = false;
                }
            },
            onNotesChange() {
                console.log('onNotesChange', this.$refs.notesEditor.invoke('getHTML'));
                this.dataTask.notes = this.$refs.notesEditor.invoke('getHTML');
            },
            clickAddCommentAndNotify(activity) {
                this.showCommentBox = true;
                // this.showCommentBox = !this.showCommentBox;
                this.isCommentAndNotifyClicked = true;
            },
            clickAddComment(activity) {
                this.showCommentBox = true;
                //  this.showCommentBox = !this.showCommentBox;
                this.isCommentAndNotifyClicked = false;
            },
            sendCommentAndNotify() {
                this.task.isViewed = true;
                this.dataTask.isViewed = true;
                this.$emit('save', {
                    task: {
                        id: this.task.id,
                        newComment: {
                            text: this.comment,
                        },
                        isNotify: true,
                        notifyTo: this.dataTask.notifyTo,
                    }
                });
                // reset comment
                this.showCommentBox = false;
                this.comment = '';
                // open the details to show new comment
                this.isMinimized = false;
            },
            saveComment() {
                this.task.isViewed = true;
                this.dataTask.isViewed = true;
                this.$emit('save', {
                    task: {
                        id: this.task.id,
                        newComment: {
                            text: this.comment,
                        },
                        isNotify: false,
                    }
                });
                // reset comment
                this.showCommentBox = false;
                this.comment = '';
                // open the details to show new comment
                this.isMinimized = false;
            },
            cancelComment() {
                this.showCommentBox = false;
                this.comment = '';
            },
            formatDatetime(ms) {
                return moment(ms).format('DD/MM/YYYY HH:mm A');
            },
            onActionsDropdownOpen(e) {
                // set comment box state
                this.showCommentBox = false;
                this.comment = '';
            },
            onCommentEdit(comment) {
                if (!comment.edit) {
                    comment.tempText = comment.text;
                    comment.edit = true;
                } else {
                    this.onCommentEditCancel(comment);
                }
            },
            onCommentEditCancel(comment) {
                delete comment.tempText;
                comment.edit = false;
            },
            onCommentSave(comment) {
                comment.text = comment.tempText;
                comment.edit = false;
                this.saveTaskComment(comment);
            },
            open() {
                this.isMinimized = false;
                this.$nextTick(() => {
                    // scroll to this element
                    this.$el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest'
                    });
                });
            },
        },
        computed: {
            ...mapState({
                currentUser: 'currentUser',
            }),
            computedPriority: {
                get() {
                    return _.find(this.priorityList, item => {
                        return item.key === this.dataTask.priority;
                    });
                },
                set(value) {
                    this.dataTask.priority = value.key;
                }
            },
            computedType: {
                get() {
                    return _.find(this.typeList, item => {
                        return item.key === this.dataTask.type;
                    });
                },
                set(value) {
                    this.dataTask.type = value.key;
                }
            },
            computedStatus: {
                get() {
                    return _.find(this.statusList, item => {
                        return item.key === this.dataTask.status;
                    });
                },
                set(value) {
                    this.dataTask.status = value.key;
                }
            },
            computedNotifyTo: {
                get() {
                    let notifyTo = this.dataTask.createdById;
                    if (!this.dataTask.notifyTo || this.dataTask.notifyTo === null) {
                        notifyTo = this.dataTask.createdById;
                    } else {
                        notifyTo = Number(this.dataTask.notifyTo);
                    }
                    let user = _.find(this.assignedToList, item => {
                        return Number(item.id) === Number(notifyTo);
                    });
                    if (user) {
                        return user;
                    }
                    return {id: 0, name: 'None'};
                },
                set(value) {
                    if (value.id) {
                        this.dataTask.notifyTo = value.id;
                    } else {
                        this.dataTask.notifyTo = null;
                    }

                }
            },
            computedAssignedTo: {
                get() {
                    let assignedTo = 0;
                    if (!this.dataTask.assignedTo || this.dataTask.assignedTo === null) {
                        assignedTo = 0;
                    } else {
                        assignedTo = Number(this.dataTask.assignedTo);
                    }
                    let user = _.find(this.assignedToList, item => {
                        return Number(item.id) === assignedTo;
                    });
                    if (user) {
                        return user;
                    }
                    return {id: 0, name: 'None'};
                },
                set(value) {
                    if (value.id) {
                        this.dataTask.assignedTo = value.id;
                    } else {
                        this.dataTask.assignedTo = null;
                    }

                }
            },
            computedComments() {
                let comments = this.task.comments;

                return comments;
            },
            isTaskOwner() {
                return !!(Number(this.task.createdById) == Number(this.currentUser.id) || (!this.task.id));
            },
            isTaskAssignedToMe(){
              return this.task.assignedTo == this.currentUser.id;
            },
            assignedChangedTask(){
                return this.task.type != this.dataTask.type
                       || this.task.assignedTo != this.dataTask.assignedTo
                       || this.task.priority != this.dataTask.priority
                       || this.task.status != this.dataTask.status;
            },
            sortedComments() {
                return this.computedComments.sort((a, b) => {
                    return b.timestamp - a.timestamp;
                });
            },
            priorityColors() {
                return {
                    'text-danger': this.dataTask.priority && this.dataTask.priority === 'high',
                    'text-warning': this.dataTask.priority && this.dataTask.priority === 'medium',
                    'text-secondary': this.dataTask.priority && this.dataTask.priority === 'low',
                };
            }
        },
        watch: {},
        components: {
            'toast-editor': Editor,
            'toast-viewer': Viewer,
            Multiselect,
            VueTimepicker,
            datePicker,
        },
        mounted: function () {
            // set listener when actions dropdown open
            $(`#actions-${this.task.id}`).on('show.bs.dropdown', this.onActionsDropdownOpen);

            let params = this.$route.params;
            let vm = this;
            this.$nextTick(() => {
                if (params && params.action && params.action == 'goToTask' && this.task.id == params.taskId) {
                    vm.isMinimized = false;
                    vm.$nextTick(() => {
                        vm.$el.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                            inline: "center",
                        });
                    });
                } else if (params && params.action && params.action == 'goToComment' && this.task.id == params.taskId) {
                    vm.isMinimized = false;
                    vm.$nextTick(() => {
                        let ref = 'comment-' + params.commentId;
                        console.log('ref.comment', ref);
                        if (vm.$refs[ref]) {
                            vm.$refs[ref].$el.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center',
                                inline: "center",
                            });
                        }
                    });

                }
            });
        },
        unmounted() {
            // unset listener when actions dropdown open
            $(`#actions-${this.task.id}`).off('show.bs.dropdown', this.onActionsDropdownOpen);
        },
    };
</script>

<template>
    <div :id="(activity && activity.activity_id > 0)?`activity-${activity.activity_id}`:`newtask-1`" class="activity-log-task" :class="{'open': !isMinimized}">
        <div class="tl-content box box-block display-block">
            <!-- <span class="arrow left" style="border-color: #FAFAFA;"></span> -->
            <div class="row d-flex justify-content-between px-1-25 flex-nowrap">

                <!-- priority label -->
                <div style="flex: 0 0 50%;">
                    <div :class="priorityColors" v-if="dataTask.priority && dataTask.priority !== 'none'">
                        <i class="fa fa-dot-circle-o"></i> {{ computedPriority.value }}
                    </div>
                    <div v-else>&nbsp;</div>
                </div>

                <!-- actions -->
                <div :id="`actions-${this.task ? this.task.id : 'new'}`" class="dropdown show float-sm-right text-right w-100" style="flex: 0 0 50%;">
                    <span class="btn btn-outline-primary w-min-lg b-dd b-act ml-0-25 waves-effect waves-light btnNotBorder"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="true">Actions <i class="fa fa-caret-down"></i></span>
                    <div class="dropdown-menu dropdown-menu-right act-dd act-dd-right animated  dropdownLinks-container">
                        <div class="dropdownLinks-links">
                            <template v-if="!isOpenNewTaskForm">
                                <a v-if="dataTask.isViewed" @click="markAsUnRead(activity)" class="dropdown-item text-primary p-1 clickable">
                                    Mark As Unread</a>
                                <a v-else @click="markAsRead(activity)" class="dropdown-item text-primary p-1 clickable">
                                    Mark As Read
                                </a>
                            </template>
                            <a @click="deleteTask(activity)" class="dropdown-item text-primary p-1 clickable">
                                Delete Task
                            </a>
                            <div v-if="!isOpenNewTaskForm" class="dropleft">
                                <a @click.prevent.stop="clickAddCommentAndNotify" class="dropdown-item p-1 clickable text-primary" data-toggle="dropdown" aria-haspopup="true"
                                   aria-expanded="false">
                                    Comment & Notify
                                </a>
                                <a @click.prevent.stop="clickAddComment" class="dropdown-item p-1 clickable text-primary" data-toggle="dropdown" aria-haspopup="true"
                                   aria-expanded="false">
                                    Comment
                                </a>
                                <div v-if="showCommentBox" class="dropdown-menu block dropdown-comment-box" style="display: block;">
                                    <b-form>
                                        <div class="dropdown-comment-box__container">
                                            <div class="mb-1"><strong>Comment: </strong></div>
                                            <b-textarea
                                                    v-model="comment"
                                                    rows="6"
                                                    max-rows="10"
                                                    class="dropdown-comment-box__textarea mb-3"
                                                    autofocus
                                            />
                                            <div v-if="isCommentAndNotifyClicked" class="form-group row px-0 mt-0" style="padding-right:10px; margin-top:5px;">
                                                <label class="col-form-label" style="padding-right:10px;">Notify To</label>
                                                <multiselect
                                                        v-model="computedNotifyTo"
                                                        :options="notifyToList"
                                                        :showLabels="false"
                                                        :option-height="29"
                                                        :max-height="203"
                                                        :close-on-select="true"
                                                        track-by="id"
                                                        label="name"
                                                />
                                            </div>
                                            <div class="dropdown-comment-box__actions">
                                                <div>
                                                    <button v-if="isCommentAndNotifyClicked" @click.prevent="sendCommentAndNotify" class="btn btn-md btn-primary">Send</button>
                                                    <button v-else @click.prevent="saveComment" class="btn btn-md btn-primary">Save</button>
                                                    <button @click.prevent="cancelComment" class="btn btn-sm btn-secondary" style="margin-left: 10px;">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </b-form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div ref="taskName" class="row form-group">
                <label class="col-form-label col-md-3 col-xs-6">Task Name</label>
                <div class="col-md-9 col-xs-18">
                    <input
                            class="form-control"
                            type="text"
                            v-model="dataTask.name"
                            @keyup="openDetails"
                            :readonly="!isTaskOwner"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="row form-group" v-if="dataTask.createdBy">
                        <label class="col-form-label col-xs-12 col-md-6 px-0">Task Created By</label>
                        <div class="col-xs-12 col-md-6 pt-0-5 px-0 px-md-15">{{dataTask.createdBy}}</div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row form-group">
                        <label class="col-form-label col-xs-6 col-md-4 px-0">Task Status</label>
                        <div class="col-xs-6 col-md-6 pt-0-5 px-0 px-md-15">{{computedStatus.value}}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-form-label col-md-3 col-xs-12">Due Date</label>
                <div class="col-md-3 col-sm-6 col-12">
                    <div class="input-group date mb-3">
                        <!-- <b-form-datepicker
                                placeholder=""
                                :options="dpOptions"
                                v-model="dataTask.dueDate"
                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', }"
                                locale="en-AU"
                                today-button
                                close-button
                                reset-button
                                @onChange="openDetails"
                        /> -->
                        <date-picker
                                :options="dpOptions"
                                v-model="dataTask.dueDate"
                                @onChange="openDetails"
                        />

                        <div class="input-group-addon">
                            <span class="fa fa-calendar"></span>
                        </div>
                    </div>

                </div>
                <div class="col-md-3 col-sm-6 col-12 mb-1">
                    <div class="input-group d-flex flex-nowrap">
                        <vue-timepicker v-model="dataTask.dueTime" drop-direction="up" format="h:mm A" @change="changeDueTime($event)"/>
                        <div class="input-group-addon w-auto">
                            <span class="fa fa-clock" aria-hidden="true"></span>
                        </div>
                    </div>
                </div>

            </div>

            <!-- option toggle -->
            <a class="content-minimizer d-inline-block px-1-25"
               @click="clickMinimize"
               v-b-tooltip.hover="{customClass: 'rs-tooltip', html: true, title:'View Task', placement:'bottomright'}"
            >....</a>

            <div v-if="!isMinimized" class="tl-content-box box box-block display-block mr-0" style="background-color: #FAFAFA;border: 0; padding: 0">
                <div v-if="isTaskOwner">
                    <toast-editor
                            ref="notesEditor"
                            :initialValue="dataTask.notes"
                            :options="{
                            minHeight: '300px',
                            language: 'en-US',
                            useCommandShortcut: true,
                            usageStatistics: false,
                            hideModeSwitch: false,
                            autofocus:false,
                            toolbarItems: [
                                ['heading', 'bold', 'italic', 'strike'],
                                ['hr', 'quote'],
                                ['ul', 'ol', 'task', 'indent', 'outdent'],
                                ['table', 'image', 'link'],
                                ['code', 'codeblock'],
                                ['scrollSync'],
                            ]
                        }"
                            :visible="true"
                            initialEditType="wysiwyg"
                            height="300px"
                            @change="onNotesChange"
                    />
                </div>
                <div v-else visible="visible" style="height: 300px;">
                    <div class="toastui-editor-defaultUI">
                        <div class="toastui-editor-main toastui-editor-ww-mode">
                            <div class="toastui-editor-main-container">
                                <div class="toastui-editor-ww-container">
                                    <div class="toastui-editor ww-mode" style="min-height: 252px;">
                                        <div class="ProseMirror" style="padding: 10px;">
                                            <toast-viewer
                                                    ref="notesViewer"
                                                    :initialValue="dataTask.notes"
                                                    height="300px"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isMinimized && computedComments && computedComments.length > 0" class="tl-content-box box box-block display-block pb-0-5 mr-0 overflow-hidden">
                <div v-for="comment of computedComments" v-bind:key="comment.id" class="row" style="position: relative; z-index: 20;">
                    <div :id="`comment-${comment.id}`" :ref="`comment-${comment.id}`" class="col comment-box position-relative">
                        <b-button
                                @click="onCommentEdit(comment)"
                                variant="link"
                                :disabled="!comment.editAllowed"
                                style="text-decoration: none; position:absolute; right: 5px; top: 5px; color: #333333; font-weight: 600;"
                                v-b-tooltip.hover.topleft title="Edit Comment">
                            <i class="fa fa-pencil"></i>
                        </b-button>
                        <div class="mb-1">
                            <strong>{{ comment.createdBy }}</strong>
                            —
                            {{ formatDatetime(comment.createdOn) }}
                        </div>
                        <div v-show="!comment.edit">
                            {{ comment.text }}
                        </div>
                        <div v-show="comment.edit">
                            <b-form-textarea no-resize rows="3" style="height: auto" v-model="comment.tempText" class="mb-1"></b-form-textarea>
                            <b-button variant="primary" @click="onCommentSave(comment)">Save</b-button>
                            <b-button variant="default" @click="onCommentEditCancel(comment)">Cancel</b-button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isMinimized" class="tl-content-box box box-block display-block px-0 mr-0">
                <div class="row pt-1" style="position: relative; z-index: 20;">
                    <div class="form-group col-md-2 col-4">
                        <label>Type</label>
                        <multiselect
                                v-model="computedType"
                                :options="typeList"
                                :showLabels="false"
                                :option-height="29"
                                :close-on-select="true"
                                track-by="key"
                                label="value"
                                :maxHeight="203"
                                :disabled="!isTaskOwner && !isTaskAssignedToMe"
                        ></multiselect>
                    </div>
                    <div class="form-group col-md-4 col-8">
                        <label>Assigned To</label>
                        <multiselect
                                v-model="computedAssignedTo"
                                :options="assignedToList"
                                :showLabels="false"
                                :option-height="29"
                                :close-on-select="true"
                                track-by="id"
                                label="name"
                                :maxHeight="203"
                                :disabled="!isTaskOwner && !isTaskAssignedToMe"
                        ></multiselect>
                    </div>
                    <div class="form-group col-md-3 col-6">
                        <label>Priority</label>
                        <multiselect
                                v-model="computedPriority"
                                :options="priorityList"
                                :showLabels="false"
                                :option-height="29"
                                :close-on-select="true"
                                track-by="key"
                                label="value"
                                :maxHeight="203"
                                :disabled="!isTaskOwner && !isTaskAssignedToMe"
                        ></multiselect>
                    </div>
                    <div class="form-group col-md-3 col-6">
                        <label>Task Status</label>
                        <multiselect
                                v-model="computedStatus"
                                :options="statusList"
                                :showLabels="false"
                                :option-height="29"
                                :close-on-select="true"
                                track-by="key"
                                label="value"
                                :maxHeight="203"
                                :disabled="!isTaskOwner && !isTaskAssignedToMe"
                        ></multiselect>
                    </div>
                </div>
            </div>
            <div v-if="!isMinimized && (isTaskOwner || (isTaskAssignedToMe && assignedChangedTask))" class="tl-content-box box box-block display-block mr-0">
                <button type="submit" class="btn btn-primary" @click="saveTask">Save</button>
                <button type="submit" class="btn" @click="closeTask">Cancel</button>
            </div>
        </div>
    </div>
</template>

<style>
    .timeline .activity-log-task .tl-content {
        margin-bottom: -1px;
    }

    .activity-log-task hr {
        -moz-border-bottom-colors: none;
        -moz-border-image: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        border-color: #EEEEEE -moz-use-text-color #FFFFFF; /* TODO: check this line please. I think it is incorrect */
        border-style: solid none;
        border-width: 1px 0;
        margin: 18px 0;
    }

    .activity-log-task .content-minimizer, .content-minimizer:visited, .content-minimizer:hover {
        color: #34495E;
        font-size: 24px;
        font-weight: bolder;
    }

    .toastui-editor-mode-switch {
        display: none !important;
    }

    .toastui-editor-toolbar-icons {
        transform: scale(0.7);
        -moz-transform: scale(0.7);
    }

    .content-minimizer {
        display: inline-block;
        cursor: pointer;
    }

    .toastui-editor-defaultUI .toastui-editor-defaultUI-toolbar {
        background-color: #ECEEEF;
    }
</style>

<style scope>
    .timeline .activity-log-task {
        padding-right: 0px;
    }

    .timeline .activity-log-task.open {
        /* padding-right: 15px; */
    }

    .dropdown-menu.dropdown-comment-box {
        /* left: -250px; */
        left: -42vw;
        top: 0px;
        box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        min-width: 62vw;
        max-width: 100%;
        background: #FFFFFF;
        border-radius: 6px;
    }

    @media screen and (min-width: 425px) {
        .dropdown-menu.dropdown-comment-box {
            left: -44vw;
            min-width: 44vw;
        }
    }

    @media screen and (min-width: 512px) {
        .dropdown-menu.dropdown-comment-box {
            left: -50vw;
            min-width: 50vw;
        }
    }

    @media screen and (min-width: 992px) {
        .dropdown-menu.dropdown-comment-box {
            left: -30vw;
            min-width: 30vw;
        }
    }

    .dropdown-menu .dropdown-comment-box__container {
        display: flex;
        flex-flow: column nowrap;
        padding: 0 10px;
    }

    .dropdown-menu .dropdown-comment-box__textarea {
        /*border: 0;*/
        padding: 0;
    }

    .dropdown-menu .dropdown-comment-box__actions {
        display: flex;
        justify-content: end;
    }

    .comment-box {
        background-color: #FFFFFF;
        border: 1px solid #DEDEDE;
        padding: 15px 15px;
        border-radius: 8px;
        margin-bottom: 15px;
    }

    .comment-box:last-child {
        margin-bottom: 0px;
    }

    /* ================================================= */
    /*                  adjusted from RS                 */
    /* ================================================= */
    /* timeline - body */
    .tl-content {
        min-width: calc(325px - 40px);
        padding: 15px;
        width: 100%;
        max-width: calc(100% - 10px);
        /* margin-right: 0; */
        background-color: #FAFAFA;
        border: 1px solid rgba(27, 30, 56, 0.25);
        border-radius: 5px;

        padding-left: 1.25rem;
        border: 1px solid rgba(0, 0, 0, 0.125);

        margin-bottom: -1px;
    }

    @media screen and (min-width: 425px) {
        .tl-content {
            max-width: 100%;
        }
    }

    .tl-content-box {
        padding: 15px;
        background-color: #FAFAFA;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 5px;
        margin: 0;
    }

    /* actions */
    .btn.btn-outline-primary.b-act {
        text-align: right;
        padding-right: 15px;
        padding-left: 15px;
        margin-top: -21px;
        height: auto;
        margin-right: -7px;
        margin-top: -10px;

        font-size: 13px;
        font-weight: 600;
        border: none;

        color: var(--green)
    }

    @media screen and (min-width: 425px) {
        .btn.btn-outline-primary.b-act {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    .btn.btn-outline-primary.b-dd:hover {
        color: #4F5D71;
        background: transparent !important;
    }

    /* ================================================= */
    /*                  GLOBAL                           */
    /* ================================================= */

    /* action dropdown menu */
    /* probably put this on activity-list (parent) */
    .act-dd.act-dd-right {
        right: -9px !important;
        transform: translate3d(0, 0, 0) !important;
    }

    .dropdownLinks-container {
        flex-direction: column !important;
        position: absolute !important;
        border: none !important;
        background-color: #FAFAFA !important;
        right: 20px !important;
        top: 28px !important;
        height: auto !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .dropdownLinks-links {
        position: relative;
        background: white;
        border: 1px solid rgba(0, 0, 0, .15) !important;
    }

    .dropdownLinks-links:after,
    .dropdownLinks-links:before {
        bottom: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .dropdownLinks-links:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #FFFFFF;
        border-width: 5px;
        right: 12px;
        background-clip: padding-box;
    }

    .dropdownLinks-links:before {
        border-color: rgba(184, 184, 184, 0);
        border-bottom-color: #B8B8B8;
        border-width: 6px;
        right: 11px;
        background-clip: padding-box;
    }

    .dropdown-menu-right {
        left: auto !important;
        right: 0;
    }

    .dropdown-menu-left {
        left: 0;
        right: auto;
    }

    .tl-item:first-child .tl-wrap {
        padding-top: 23px;
    }

    .tl-item:first-child .tl-wrap:before {
        margin-top: 23px;
    }

    .tl-item:first-child .tl-wrap i {
        top: 15px;
    }

    .tl-wrap.b-a-primary {
        padding-left: 23px;
    }

    .tl-wrap.b-a-primary:before {
        width: 25px;
        height: 25px;
        top: 10px;
        left: -14.5px;
        background: #142742;
        border-color: #142742;
    }

    .tl-wrap.b-a-primary i.left {
        position: absolute;
        top: 1.15rem;
        left: -8px;
        margin-top: 18px;
        width: auto;
        height: auto;
        font-size: 13px;
        color: #FFFFFF;
    }

    .tl-wrap.b-a-primary .fa-pencil-square-o {
        left: -7.5px;
        top: 1.3rem;
    }

    .tl-wrap.b-a-primary .fa-external-link {
        top: 1.25rem;
        left: -8px;
    }

    .timeline .tl-content {
        padding-left: 1.25rem;
        border: 1px solid rgba(0, 0, 0, 0.125);
    }

    .tl-wrap.b-a-primary {
        border-left-width: 1px;
        border-color: #DCDCDC !important;
    }

    .tl-wrap.b-a-primary i.left {
        position: absolute;
        top: 1.15rem;
        left: -8px;
        margin-top: 18px;
        width: auto;
        height: auto;
        font-size: 13px;
        color: #FFFFFF;
    }

    /* Date and Time Input */
    .b-form-btn-label-control.form-control,
    .vue__time-picker input.display-time {
        height: 32px;
        font-weight: normal;
        width: 100%;
        border: 1px solid rgba(28, 31, 57, 0.1);
        -webkit-appearance: none;
    }

    .vue__time-picker {
        width: calc(100% - 32px);
    }

    .b-form-datepicker {
        padding: 4px
    }

    /* Datepicker Icon */
    .b-form-datepicker #__BVID__100.btn.h-auto {
        position: absolute;
        opacity: 0;
    }

    .b-form-btn-label-control.form-control > .form-control {
        display: flex;
        align-items: center;
    }

</style>
