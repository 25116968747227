<template>
    <div>
        <!-- Title and Sort -->
        <div class="row activity-list-header px-0 mb-2 py-2 no-gutter">
            <div class="col-6">
                <h4>Activity List</h4>
            </div>

            <div class="col-6">
                <div class="dropdown float-right show">
                    <b-dropdown ref="sort-dropdown" class="dropdown-options mr-0" right>
                        <template v-slot:button-content>
                            <div v-if="activitySortTypeText===''">Sort by</div>
                            <div v-else>{{activitySortTypeText}}</div>
                            <div class="dropdown-arrow">
                                <i class="fa fa-chevron-down"></i>
                            </div>
                        </template>
                        <b-dropdown-item
                                @click="changeActivitySortBy(activitySortTypes.email)"
                                class="text-primary clickable">
                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                            <span class="ml-2">{{activitySortTypes.email}}</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                                @click="changeActivitySortBy(activitySortTypes.external)"
                                class="text-primary clickable">
                            <i class="fa fa-external-link" aria-hidden="true"></i>
                            <span class="ml-2">{{activitySortTypes.external}}</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                                @click="changeActivitySortBy(activitySortTypes.assignment)"
                                class="text-primary clickable">
                            <i class="fa fa-user" aria-hidden="true"></i>
                            <span class="ml-2">{{activitySortTypes.assignment}}</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                                @click="changeActivitySortBy(activitySortTypes.status_change)"
                                class="text-primary clickable">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                            <span class="ml-2">{{activitySortTypes.status_change}}</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
        </div>
        <div v-if="computedActivityWithType.length > 0" class="row no-gutter">
            <div class="offset-sm-4 col-sm-8 offset-md-6 col-md-6 col-12 mb-4">
                <!-- search filter -->
                <b-input ref="search-filter" placeholder="Search or Filter results" v-model="filter" style="height: 41px;"/>
            </div>
        </div>

        <!-- <div class="timeline overflow-x-auto overflow-y-hidden"> -->
        <div class="timeline row no-gutter">
            <div v-if="isOpenNewTaskForm"
                 :data-activity-id="'newtask-new-1'"
                 class="tl-item tl-activity-item col-12 pr-1-25"
            >
                <div class="d-flex nowrap">
                    <div class="p-0 icon-holder">
                        <div class="vertical-line"></div>
                        <div class="icon-dot">
                            <i class='fa fa-pencil-square-o'></i>
                        </div>
                    </div>
                    <div class="px-4 flex-grow-1 pb-4">
                        <activity-item-task
                                ref="activity-new-task"
                                :task="newTask"
                                :assigned-to-list="assignedToList"
                                :notify-to-list="computedNotifyToList"
                                :openNewTaskForm="isOpenNewTaskForm"
                                @save="createTask"
                                @close="closeNewTask"
                        />
                    </div>
                </div>
            </div>

            <activity-item v-for="item in computedActivityWithFilter"
                           v-bind:key="item.id"
                           class="col-12 pr-1-25"
                           :item="item"
                           :assignedToList="assignedToList"
                           :notifyToList="computedNotifyToList"
                           @loadActivity="loadActivity"
                           @saveTask="saveTask"
                           @closeTask="closeTask"
                           @markAsUnReadActivity="markAsUnReadActivity"
                           @markAsReadActivity="markAsReadActivity"
                           @deleteTask="deleteTask"
                           @openMenuForEmailAttachments="openMenuForEmailAttachments"
            />
            <vue-context ref="rightClickMenuForEmailAttachments" class="context-menu-right-click list-group">
                <ul slot-scope="item" class="d-flex btn-group-vertical">
                    <li v-if="isOpenOnClaimPage && item && item.data && item.data.type=='image'"
                        class="text-left font-12 font-nunito--semibold btn btn-light btn-block"
                        @click="menuForEmailAttachments($event.target, {type:11, item:item.data})">Add to Claim Images
                    </li>
                    <li v-if="isOpenOnClaimPage && item && item.data && item.data.type!='image'"
                        class="text-left font-12 font-nunito--semibold btn btn-light btn-block"
                        @click="menuForEmailAttachments($event.target, {type:10, item:item.data})">Add to Claim Files
                    </li>
                    <li v-if="isOpenOnAssessmentPage && item && item.data && item.data.type!='image'"
                        class="text-left font-12 font-nunito--semibold btn btn-light btn-block"
                        @click="menuForEmailAttachments($event.target, {type:20, item:item.data})"
                    >Add to Assessment Files
                    </li>
                    <li v-if="isOpenOnAssessmentPage && item && item.data && item.data.type=='image'"
                        class="text-left font-12 font-nunito--semibold btn btn-light btn-block"
                        @click="menuForEmailAttachments($event.target, {type:21, item:item.data})"
                    >Add to Assessment Images
                    </li>
                    <li
                            @click="menuForEmailAttachments($event.target, {type:30, item:item.data})"
                            class="text-left font-12 font-nunito--semibold btn btn-light btn-block"
                    >Delete
                    </li>
                </ul>
            </vue-context>
        </div>
    </div>

</template>

<script>
    /* eslint-disable */
    import ActivityItemTask from './activity/activity-item-task';
    import ActivityItem from './activity/activity-item';
    import Axios from "axios";
    import _ from "lodash";
    import {VueContext} from 'vue-context';
    import {mapGetters} from "vuex";

    export default {
        name: "block-activity",
        components: {
            ActivityItem,
            ActivityItemTask,
            VueContext,
        },
        props: {
            activity: {
                type: Array,
                default() {
                    return [];
                }
            },
            classes: {
                'table-responsive': true
            },
            styles: {},
        },
        data() {
            return {
                filter: '',
                isOpenNewTaskForm: false,
                usersAll: [],
                newTask: {
                    name: '',
                    dueDate: null,
                    dueTime: "",
                    notes: '',
                    type: "to-do",
                    assignedTo: 0,
                    status: "none",
                    priority: "none",
                },
                // Sort
                activitySortTypeText: '',
                activitySortTypes: {
                    email: 'Email',
                    external: 'External',
                    assignment: 'Assignment',
                    status_change: 'Status/Change',
                },
            };
        },
        methods: {
            openMenuForEmailAttachments: function (event, item) {
                this.$refs.rightClickMenuForEmailAttachments.open(event, item);
            },
            menuForEmailAttachments: function (event, data) {
                let type = data.type;
                let item = data.item;
                if (type == 30) {
                    if (!confirm(`Confirm you want to DELETE this attachment`)) {
                        return;
                    }
                    Axios.post(`/ir/activity/file/remove`, {fileId: item.id})
                        .then(response => {
                            if (response.data._status) {
                                this.$toast.success('file has been deleted from attachments');
                                this.$set(item, 'isDeleted', true);
                            }
                            console.log(response);
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
                let payload = {};
                if (type == 11 || type == 10) {
                    payload = {
                        type: 'claim',
                        claimId: this.$route.params.claimId,
                        fileId: item.id,
                    };
                } else if (type == 21 || type == 20) {
                    payload = {
                        type: 'assessment',
                        assessmentId: this.$route.params.assessmentId,
                        fileId: item.id,
                    };
                } else {
                    return;
                }
                NProgress.start();
                Axios.post(`/ir/activity/file/copy`, payload)
                    .then(response => {
                        if (response.data._status) {
                            //  this.$toast.success(res.data.msg);
                            if (type == 20 || type == 10) {
                                this.$emit('loadFiles');
                            } else if (type == 21 || type == 11) {
                                this.$emit('loadImages');
                            }
                            if (type == 11 || type == 10) {
                                this.$set(item, 'isCopyToClaim', true);
                            } else if (type == 21 || type == 20) {
                                this.$set(item, 'isCopyToAssessment', true);
                            }
                        }
                    })
                    .catch(error => {
                        this.$toast.error(err);
                    }).finally(() => {
                    NProgress.done();
                });
            },
            showNewTaskForm() {
                this.newTask = {
                    name: '',
                    dueDate: null,
                    dueTime: "",
                    notes: '',
                    type: "to-do",
                    assignedTo: 0,
                    status: "none",
                    priority: "none",
                };
                this.isOpenNewTaskForm = true;
            },
            markAsReadActivity() {

            },
            markAsUnReadActivity() {

            },
            saveTask(task) {
                console.log('saveTask', task);
                NProgress.start();
                let aid = this.$route.params.assessmentId ? this.$route.params.assessmentId : 0;
                let cid = this.$route.params.claimId ? this.$route.params.claimId : 0;
                Axios.post('/ir/activity/task/' + task.id, {
                    task: task,
                    assessmentId: aid,
                    claimId: cid,
                }).then(response => {
                    if (response.data && response.data._status && response.data._update && response.data._update == 'activity') {
                        this.loadActivity();
                        if (task.delete) {
                            this.$toast.success("Task has been deleted");
                        } else if (task.newComment || (task.comments && task.comments.length > 0 && !task.name)) {
                            this.$toast.success("Comment has been saved");
                        } else {
                            this.$toast.success("Task has been saved");
                        }
                    } else if (response.data && !response.data._status) {
                        this.$toast.error("Task hasn't been saved");
                    }
                }).catch(error => {
                    this.$toast.error("Task hasn't been saved");
                    console.log(error);
                }).finally(() => {
                    NProgress.done();
                });
            },
            deleteTask(task) {
                console.log('deleteTask', task);
                task.delete = true;
                this.saveTask(task);
            },
            createTask(data) {
                console.log('createTask', data);
                NProgress.start();
                let aid = this.$route.params.assessmentId ? this.$route.params.assessmentId : 0;
                let cid = this.$route.params.claimId ? this.$route.params.claimId : 0;
                Axios.post('/ir/activity/task/new', {
                    newTask: data.task,
                    assessmentId: aid,
                    claimId: cid,
                }).then(response => {
                    if (response.data && response.data._status && response.data._update && response.data._update == 'activity') {
                        this.closeNewTask();
                        this.loadActivity();
                        this.$toast.success("Task has been created");
                    } else if (response.data && !response.data._status) {
                        this.$toast.error("Task hasn't been created");
                    }
                }).catch(error => {
                    this.$toast.error("Task hasn't been saved");
                    console.log(error);
                }).finally(() => {
                    NProgress.done();
                });
            },
            closeTask() {

            },
            closeNewTask() {
                this.newTask = {
                    name: '',
                    dueDate: null,
                    dueTime: "",
                    notes: '',
                    type: "to-do",
                    assignedTo: 0,
                    status: "none",
                    priority: "none",
                };
                this.isOpenNewTaskForm = false;
            },
            loadUsers: function (pageNumber = 1, usersLoaded = (/*users*/) => {
            }) {
                NProgress.start();
                Axios.get('/ir/users', {
                    params: {
                        pageNum: pageNumber,
                        sort_by: 'user_id',
                        sort_type: 'DESC'
                    }
                }).then(response => {
                    //console.log('Users loaded',response.data.users);
                    let users = _.map(response.data.users, item => {
                        item.id = Number(item.id);
                        return item;
                    });
                    users = _.sortBy(users, ['id']);
                    // console.log('Users mapped', users);
                    this.usersAll = users;
                    usersLoaded(users);
                }).catch(error => {
                    console.log(error);
                })
                    .finally(() => {
                        NProgress.done();
                    });
            },
            loadActivity: function (timestamp = 0) {
                if (timestamp > 0) {
                    this.$emit('loadActivity', timestamp);
                } else {
                    this.$emit('loadActivity');
                }
            },
            changeActivitySortBy(sort_by) {
                if (sort_by === this.activitySortTypeText) {
                    this.activitySortTypeText = '';
                } else {
                    this.activitySortTypeText = sort_by;
                }
            },
            filterActivity(activityText, query) {
                let text = '';
                if (!query) {
                    text = noteText;
                } else {
                    console.log('activityText', activityText);
                    if (activityText) {
                        text = activityText.replaceAll(new RegExp(query, 'iug'), `<span style="background-color: yellow">${query}</span>`);
                    }
                }

                return text;
            },
        },
        computed: {
            ...mapGetters({
                isNeedUpdateActivity: "updater/isNeedUpdateActivity",
                timestampForUpdateActivity: 'updater/getTimestampForUpdateActivity',
            }),
            isOpenOnAssessmentPage() {
                return !!(this.$route.params.assessmentId > 0);
            },
            isOpenOnClaimPage() {
                return !!(this.$route.params.claimId > 0);
            },
            computedActivityWithType() {
                if (this.activitySortTypeText === '') {
                    return this.activity;
                }
                let data = [];
                if (this.activitySortTypeText == 'Email') {
                    data = _.filter(this.activity, function (a) {
                        return (a.type == "Email");
                    });
                } else if (this.activitySortTypeText == 'External') {
                    data = _.filter(this.activity, function (a) {
                        return (a.type == "External");
                    });
                } else if (this.activitySortTypeText == 'Assignment') {
                    data = _.filter(this.activity, function (a) {
                        return (a.type == "Assignment" || a.type == "Task");
                    });
                } else if (this.activitySortTypeText == 'Status/Change') {
                    data = _.filter(this.activity, function (a) {
                        return (a.type == "Status");
                    });
                }
                return data;

            },
            computedActivityWithFilter() {
                if (!this.filter) {
                    return this.computedActivityWithType;
                }
                let data = [];
                this.computedActivityWithType.forEach(a => {
                    if (a.type == 'Task') {
                        let task = a.task;
                        let f = this.filterActivity(task.notes, this.filter);
                        if (task.notes != null && f != task.notes) {
                            data.push(a);
                        } else {
                            let f = this.filterActivity(task.name, this.filter);
                            if (task.name != null && f != task.name) {
                                data.push(a);
                            } else {
                                let comments = task.comments;
                                let status_comment = false;
                                comments.forEach(c => {
                                    let cc = this.filterActivity(c.text, this.filter);
                                    if (c.text != null && cc != c.text) {
                                        status_comment = true;
                                    }
                                });
                                if (status_comment) {
                                    data.push(a);
                                }
                            }
                        }
                    } else if (a.type == 'Email') {
                        let email = a.email;
                        let f = this.filterActivity(email.subject, this.filter);
                        if (email.subject != null && f != email.subject) {
                            data.push(a);
                        } else {
                            let f = this.filterActivity(email.from, this.filter);
                            if (email.from != null && f != email.from) {
                                data.push(a);
                            } else {
                                let f = this.filterActivity(email.html_text, this.filter);
                                if (email.html_text != null && f != email.html_text) {
                                    data.push(a);
                                } else {
                                    let files = email.files;
                                    let status_files = false;
                                    files.forEach(c => {
                                        let cc = this.filterActivity(c.name, this.filter);
                                        if (c.name != null && cc != c.name) {
                                            status_files = true;
                                        }
                                    });
                                    if (status_files) {
                                        data.push(a);
                                    }
                                }
                            }
                        }
                    } else {
                        //let f = this.filterActivity(n.action_description, this.filter);
                        //if (n.action_description!=null && f != n.action_description) {
                        //    data.push(n);
                        //} else {
                        //    f = this.filterActivity(n.entry_date, this.filter);
                        //    if (n.entry_date!=null && f != n.entry_date) {
                        //        data.push(n);
                        //    } else {
                        //        f = this.filterActivity(n.entry_time, this.filter);
                        //        if (n.entry_time!=null && f != n.entry_time) {
                        //            data.push(n);
                        //        }
                        //    }
                        //}
                    }
                });

                return data;
            },
            computedNotifyToList() {
                let users = [];
                this.usersAll.forEach((user) => {
                    users.push({
                        id: user.id,
                        name: user.fullName,
                    });
                });
                return users;
            },
            assignedToList() {
                let users = [{id: 0, name: 'None'}];
                this.usersAll.forEach((user) => {
                    users.push({
                        id: user.id,
                        name: user.fullName,
                    });
                });
                return users;
            },
        },
        mounted() {
            this.loadUsers();
        },
        watch: {
            isNeedUpdateActivity(newVal) {
                if (newVal === true) {
                    console.log('loadActivity', this.timestampForUpdateActivity);
                    this.loadActivity(this.timestampForUpdateActivity);
                }
            }
        }
    };
</script>

<style scoped>

    .v-context.context-menu-right-click.list-group {
        width: 140px;
    }

    .icon-holder {
        vertical-align: top;
        position: relative;
    }

    .icon-holder .vertical-line {
        border-right: lightgray 1px solid;
        position: absolute;
        top: 40px;
        bottom: 0;
        left: 0;
        width: 20px;
    }

    .icon-dot {
        color: white;
        font-size: 14px;
        border-radius: 99999px;
        width: 40px;
        height: 40px;
        background-color: var(--green);
        margin: 0;
        line-height: 40px;
        text-align: center;
        font-weight: bolder;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon-dot .bx {
        font-size: 24px;
    }

    .lineholder-l {
        width: 20px;
        border-right: 1px solid lightgray;
        padding: 0;
    }

    .lineholder-r {
        width: 20px;
        padding: 0;
    }

    .dropdown-arrow {
        padding: 5px 0 3px 15px;
    }

</style>
