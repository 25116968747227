<script>
    /* eslint-disable no-mixed-spaces-and-tabs */
    /* eslint-disable no-tabs */
    export default {
        name: 'date-picker',
        props: {
            options: {},
            value: {
                type: String,
                default: ''
            },
            isDisabled: {
                type: Boolean,
                default: false
            }
        },
        mounted: function () {
            var vm = this;
            $(this.$el)
                .val(this.value)
                .datepicker(this.options)
                .on('change', function () {
                    vm.$emit('input', this.value);
                    vm.$emit('onChange');
                });
        },

        watch: {
            value: function (value) {
                $(this.$el).datepicker('setDate', value);
            },
            options: function (options) {
                $(this.$el).datepicker(options);
            }
        },
        destroyed: function () {
            $(this.$el).off().datepicker('destroy');
        }
    };

</script>

<template>
    <input type="text" class="form-control" placeholder="dd/mm/yyyy" :disabled="isDisabled">
</template>
